import { gql } from "@apollo/client"
import { Box, Button, Icon, Modal, Notification, Typography } from "components"
import { StatusTab } from "components/advance/StatusTab"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import { compose, withFormik, withHooks, withStores } from "enhancers"
import { PageContent } from "layouts/PageContent"
import { every, isEmpty } from "lodash"
import paths from "routes/paths"
import styled from "styled-components"
import FilterClaimRequests from "./FilterModal"
import { AppColor } from "theme/app-color"
import RejectModal from "./detail/reject-modal"
import { functions } from "configs"
import ClaimListTab from "./status-tab/ClaimListTab"
import { useLocation } from "react-router-dom"
import { filterEmptyValues } from "utils/helper"

const HistoryContainer = styled.div`
  margin-top: 16px;
`

const FooterContainer = styled("div")`
  padding: 16px;
  background-color: ${AppColor["White / White"]};
  border-top: 1px solid ${AppColor["Text/Gray"]};
  position: fixed;
  bottom: 0;
  z-index: 1;
  max-width: 752px;
  width: calc(100% - 32px);
`

const PhantomContainer = styled("div")`
  display: block;
  padding: 20px;
  height: 72px;
  width: calc(100% - 32px);
`

const FlexBox = styled(Box)<{ mb?: string; mt?: string }>`
  display: flex;
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
`
const RejectActionButton = styled(Button)`
  width: 100%;
  background-color: ${AppColor["White / White"]};
  &:focus {
    background-color: ${AppColor["White / White"]};
  }
  &:active {
    background-color: ${AppColor["White / White"]};
  }
  &:hover {
    background-color: ${AppColor["System/Error Light Hover"]};
  }
`
const ActionButton = styled(Button)`
  width: 100%;
`

const ClaimTab = styled.div<{ tabName: string; selectTab: string }>`
  display: ${(props) => (props.tabName === props.selectTab ? "inline" : "none")};
`

const ClaimRequestsPageComponent = (props: any) => (
  <>
    <RejectModal
      title={`${props.claimRequestRange} รายการ`}
      isOpen={props.isRejectModalOpen}
      onClose={props.handleCloseRejectModal}
      onConfirm={props.handleConfirmReject}
      amount={null}
      rejectReasons={props.rejectReasons}
    />
    <PageContent
      title="รายการขอเบิก"
      type="secondary"
      titleCentered
      onBack={props.handleClickBack}
      showFilter
      hasFilter={props.hasFilter}
      openFilterModal={props.openFilterModal}
      filterCount={props.filterCount}
      noScroll={true}
    >
      <Box padding="16px">
        <StatusTab
          role={props.currentUser?.role}
          selectedStatus={props.filteredStatus}
          onClick={(status: EnumClaimRequestStatus) => props.handleChangeStatus(status)}
          hasCancel={false}
        />
        <HistoryContainer>
          <ClaimListTab
            externalCanSubmit={props.canSubmit}
            waitingRequest={(data: any) => props.setWaitingRequest(data)}
            windowHeight={props.windowHeight}
          />
        </HistoryContainer>
      </Box>
      {props.showFooter && (
        <>
          <PhantomContainer />
          <FooterContainer>
            <Typography variant="h3" color="Text/Primary Text">
              {`ผลการพิจารณา (${props.claimRequestRange} รายการ)`}
            </Typography>
            <FlexBox mt="16px" mb="8px">
              <RejectActionButton
                variant="outlined"
                color={AppColor["Other/Danger"]}
                style={{
                  borderColor:
                    props.disabledRejectButton || props.claimRequestRange === 0
                      ? AppColor["Text/Gray Success"]
                      : AppColor["Other/Danger"],
                }}
                onClick={props.handleOpenRejectModal}
                mr="16px"
                disabled={props.disabledRejectButton || props.claimRequestRange === 0}
              >
                ตรวจสอบเพิ่มเติม
              </RejectActionButton>
              <ActionButton
                onClick={props.handleOpenApproveModal}
                variant="contained"
                disabled={props.disabledApproveButton || props.claimRequestRange === 0}
              >
                อนุมัติทั้งหมด
              </ActionButton>
            </FlexBox>
          </FooterContainer>
        </>
      )}
    </PageContent>
  </>
)

const API = {
  APPROVE_ALL_REQUEST: gql`
    mutation APPROVE_ALL_REQUEST($claimLists: [JSON!]!) {
      approveAllClaimRequest(input: { claimLists: $claimLists }) {
        id
        type
        status
        createdAt
      }
    }
  `,
  REJECT_ALL_REQUEST: gql`
    mutation REJECT_ALL_REQUEST($claimLists: [JSON!]!, $rejectReason: JSON, $remark: String) {
      rejectAllClaimRequest(input: { claimLists: $claimLists, rejectReason: $rejectReason, remark: $remark }) {
        id
        type
        status
        createdAt
      }
    }
  `,
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS {
      requestConfigs {
        workflows
      }
    }
  `,
  GET_EMPLOYEE_OPTONS: gql`
    query GET_EMPLOYEE_OPTONS {
      getEmployeeOptions {
        label
        value
      }
    }
  `,
  GET_ACTION_ALL_COUNT: gql`
    query GET_ACTION_ALL_COUNT($claimLists: [JSON!]!) {
      actionAllCount(input: { claimLists: $claimLists })
    }
  `,
}

const enhancer = compose(
  withFormik({}),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUserInfo } = props
    const { useQuery, useEffect, useCallback, useMemo, useState, useMutation, useUrlParam } = hooks

    const location = useLocation()

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [location.pathname])

    const currentUser = useMemo(() => {
      return currentUserInfo?.employee
    }, [currentUserInfo])

    const queryParams = useUrlParam()
    const { tab, filters = {} } = queryParams

    const [windowHeight, setWindowHeight] = useState(window.innerHeight)

    useEffect(() => {
      const handleResize = () => {
        setWindowHeight(window.innerHeight)
      }

      // Set up event listener for window resize
      window.addEventListener("resize", handleResize)

      // Clean up event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [])

    const [waitingRequest, setWaitingRequest] = useState([])

    const [approveAllRequest] = useMutation(API.APPROVE_ALL_REQUEST)
    const [rejectAllRequest] = useMutation(API.REJECT_ALL_REQUEST)
    const { data: configs } = useQuery(API.GET_CLAIM_REQUEST_CONFIGS)
    const [canSubmit, setCanSubmit] = useState(true)
    const [hasFilter, setHasFilter] = useState(false)
    const [disabledApproveButton, setDisabledApproveButton] = useState(false)
    const [disabledRejectButton, setDisabledRejectButton] = useState(false)
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)
    const [filterCount, setFilterCount] = useState(0)
    const {
      data: employee,
      loading: loadingEmployee,
      error: errorEmployee,
    } = useQuery(API.GET_EMPLOYEE_OPTONS, {
      fetchPolicy: "network-only",
    })

    const getActionAllCountVariables = useMemo(() => {
      const claimLists = waitingRequest.map((claimRequest: any) => ({
        requestId: claimRequest.id,
        seq: claimRequest.currentWorkflow,
      }))
      return { claimLists }
    }, [waitingRequest])
    const { data: claimRequestRangeRes } = useQuery(API.GET_ACTION_ALL_COUNT, { variables: getActionAllCountVariables })
    const claimRequestRange = useMemo(() => claimRequestRangeRes?.actionAllCount || 0, [claimRequestRangeRes])

    const employeeOptions = useMemo(() => {
      if (loadingEmployee || errorEmployee) {
        return []
      }
      return employee?.getEmployeeOptions || []
    }, [employee, loadingEmployee, errorEmployee])

    const handleChangeStatus = useCallback(
      (status: EnumClaimRequestStatus) => {
        paths.claimRequestsPath({ tab: status, filters: filters }).push()
      },
      [filters],
    )

    const handleClickBack = useCallback(() => {
      paths.landingPath().push()
    }, [])

    const openFilterModal = useCallback(() => {
      // @ts-ignore
      Modal.open({
        className: "FilterModal",
        children: <FilterClaimRequests employeeOptions={employeeOptions} initialValues={filters} />,
        cancelButtonLabel: "ปิดหน้าต่างนี้",
        cancelButtonVariant: "outlined",
        okButtonLabel: "ยืนยัน",
        okButtonVariant: "contained",
        onOk: async ({ close, values }: any) => {
          paths.claimRequestsPath({ tab: tab, filters: filterEmptyValues(values) }).push()
          setFilterCount(Object.values(values).filter((item) => !!item).length)
          setCanSubmit(true)

          // @ts-ignore
          Modal.close()
        },
      })
    }, [employeeOptions, filters, tab])

    const handleCloseErrorModal = useCallback((confirmProps: any) => {
      confirmProps.close()
      setDisabledApproveButton(false)
      setDisabledRejectButton(false)
    }, [])

    const refetchClaimList = useCallback(async () => {
      const filterValues = filters
      if (!isEmpty(filterValues)) {
        setHasFilter(true)
        setFilterCount(Object.values(filterValues).filter((item) => !!item).length)
      }
      if (every(filterValues, (value) => !value)) {
        setHasFilter(false)
        setFilterCount(0)
      }
    }, [filters])

    const errorAlert = useCallback(
      (message: any) => {
        // @ts-ignore
        Modal.alert({
          className: "deleteConFirmModal",
          title: "",
          children: (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box mb="16px">
                <Icon width="40px" height="40px" name="Cancel" color={AppColor["Other/Danger"]} />
              </Box>
              <Box justifyContent="flexStart">
                <Typography variant="h3" color="Text/Black2">
                  ทำรายการไม่สำเร็จ
                </Typography>
              </Box>
              <Box>
                <Box display="flex" justifyContent="flex-start" mt="8px">
                  <Typography variant="h4" color="Text/Gray Preview">
                    {message || ""}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="flex-start" mt="4px" mb="16px">
                  <Typography variant="body1" color="Text/Gray Preview">
                    * เพื่อลดข้อผิดพลาด กรุณาดำเนินการทีละประเภท
                  </Typography>
                </Box>
              </Box>
            </Box>
          ),
          okButtonLabel: "ตกลง",
          okButtonVariant: "contained",
          buttonWidth: "100%",
          onOk: handleCloseErrorModal,
        })
      },
      [handleCloseErrorModal],
    )

    const handleConfirmApprove = useCallback(async () => {
      try {
        setDisabledApproveButton(true)
        setDisabledRejectButton(true)
        const claimLists = waitingRequest.map((claimRequest: any) => ({
          requestId: claimRequest.id,
          seq: claimRequest.currentWorkflow,
        }))
        await approveAllRequest({ variables: { claimLists } })
        setCanSubmit(true)
        // @ts-ignore
        Notification.success("บันทึกข้อมูลสำเร็จ")
        setDisabledApproveButton(false)
        setDisabledRejectButton(false)
      } catch (e: any) {
        if (e) {
          errorAlert(e.message)
          setDisabledApproveButton(false)
          setDisabledRejectButton(false)
        }
      }
    }, [approveAllRequest, waitingRequest, errorAlert])

    const handleOpenApproveModal = useCallback(async () => {
      // @ts-ignore
      Modal.open({
        children: (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Icon width="40px" height="40px" name="CheckCircle" color={AppColor.Success} />
            <Box mt="16px">
              <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
                ยอมรับการเบิกสวัสดิการ ?
              </Typography>
            </Box>
            <Box mt="8px">
              <Typography variant="body1" color={AppColor["Text/Placeholder"]}>
                อนุมัติการเบิก
              </Typography>
            </Box>
            <Box mt="4px" mb="16px">
              <Typography variant="h5" color={AppColor["Primary/Primary Text"]} isCurrency>
                {claimRequestRange} รายการ
              </Typography>
            </Box>
          </div>
        ),
        onOk: () => {
          handleConfirmApprove()
          // @ts-ignore
          Modal.close()
        },
        okButtonLabel: "อนุมัติการเบิก",
        okButtonVariant: "contained",
        cancelButtonLabel: "ปิดหน้าต่างนี้",
        cancelButtonVariant: "outlined",
      })
    }, [claimRequestRange, handleConfirmApprove])

    const handleOpenRejectModal = useCallback(() => {
      setIsRejectModalOpen(true)
    }, [])
    const handleCloseRejectModal = useCallback(() => {
      setIsRejectModalOpen(false)
    }, [])

    const handleConfirmReject = useCallback(
      async (rejectReason: any, remark?: string) => {
        try {
          setDisabledApproveButton(true)
          setDisabledRejectButton(true)
          const claimLists = waitingRequest.map((claimRequest: any) => ({
            requestId: claimRequest.id,
            seq: claimRequest.currentWorkflow,
          }))
          await rejectAllRequest({ variables: { claimLists, rejectReason: rejectReason, remark } })
          setCanSubmit(true)
          // @ts-ignore
          Notification.success("บันทึกข้อมูลสำเร็จ")
          setDisabledApproveButton(false)
          setDisabledRejectButton(false)
        } catch (e: any) {
          if (e) {
            errorAlert(e.message)
            setDisabledApproveButton(false)
            setDisabledRejectButton(false)
          }
        }
      },
      [waitingRequest, errorAlert, rejectAllRequest],
    )

    useEffect(() => {
      if (canSubmit) {
        const fetchData = () => {
          refetchClaimList()
          setCanSubmit(false)
        }
        fetchData()
      }
    }, [canSubmit, refetchClaimList])

    const currentUserLv = useMemo(() => {
      // only bam
      if (currentUser) {
        if (functions.validate["findEmployeeLv"] === undefined) {
          return "approvers10kLv6"
        }
        return functions.validate["findEmployeeLv"](currentUser)
      }
      return "approvers10kLv6"
    }, [currentUser])

    const showFooter = useMemo(() => {
      if (currentUserLv) return currentUserLv !== "approvers10kLv6" && tab === EnumClaimRequestStatus.WAITING
      return false
    }, [currentUserLv, tab])

    const rejectReasons = useMemo(() => {
      if (configs && currentUserLv) {
        return (
          configs?.requestConfigs.workflows.find((workflow: any) => workflow.custom === currentUserLv)
            ?.rejectedReasons || []
        )
      }
      return []
    }, [configs, currentUserLv])

    const infiniteScrollHeight = useMemo(() => {
      const height = windowHeight
      switch (height) {
        case height < 680:
          return showFooter ? 500 - 125 : 500
        default:
          return showFooter ? "calc(80vh - 125px)" : "80vh"
      }
    }, [showFooter, windowHeight])

    const tabStatus = [
      EnumClaimRequestStatus.WAITING,
      EnumClaimRequestStatus.WAITING_PAYMENT_CONFIRM,
      EnumClaimRequestStatus.APPROVED,
      EnumClaimRequestStatus.REJECTED,
    ]

    return {
      handleChangeStatus,
      claimRequestRange,
      filteredStatus: tab,
      handleClickBack,
      currentUser,
      openFilterModal,
      hasFilter,
      showFooter,
      handleOpenApproveModal,
      handleOpenRejectModal,
      handleCloseRejectModal,
      handleConfirmReject,
      disabledApproveButton,
      disabledRejectButton,
      isRejectModalOpen,
      rejectReasons,

      filterCount,
      canSubmit,

      setWaitingRequest,
      windowHeight: infiniteScrollHeight,

      tabStatus,
    }
  }),
)

const ClaimRequestPage = enhancer(ClaimRequestsPageComponent)

export default ClaimRequestPage
